import React from 'react'
import {Heading,SimpleGrid,Box,Text} from '@chakra-ui/react'
//import {StaticImage} from 'gatsby-plugin-image'
import {motion} from 'framer-motion'
//import {Link} from 'gatsby'
import MeinSeo from '../components/seo'
import MyContext from '../context/MyContext'

export default function Eponate(){
  const {engl,setEng} = React.useContext(MyContext)

return(
<motion.div
initial={{opacity:0,y:-100,paddingBottom:"100px"}}
animate={{opacity:1,y:10}}
transition={{duration:1.4}}
>
  <MeinSeo title="Exponate der Ausstellung " description='Informationstexte QR-COde '/>
<Heading mt="60px" variant="Titel">
Exponate
</Heading>

<Text variant='Bild'>Diese Texte werden von den jeweiligen Exponaten in Bacharach und Oberwesel über QR-Code aufgerufen.</Text>
<Text py="2"as="button" pointer="cursor " onClick={()=>setEng(!engl)}>&rarr; {!engl?"german":"english"}</Text>
<SimpleGrid columns={[1,1,2]} gap={5} >
<Box>
  {engl?
<Text id="P1" variant='solid'>
<span style={{fontWeight:800}}>1 Bruchstücke der Installation von Karl-Martin Hartmann</span> Toleranz vor Augen, Bacharach, 2007–2010. Leihgabe von Thea Feldhege & Karl Heinz Schleis. Karl-Martin Hartmann (*1948) hat 2007 bis 2010 weithin sichtbare Fenster in Zusammenarbeit von Peter Keber und dem Bauverein Wernerkapelle in der zum Mahnmal gestalteten Ruine mit dem Text aus Heinrich Heines Rabbi von Bacherach gestaltet. Weitere Teilstücke werden in Bacharach gezeigt. 
</Text>
:
<Text id="P1">
<span style={{fontWeight:800}}>1 Fragment of the installation by Karl-Martin Hartmann:</span> Tolerance in mind. Loan from Thea Feldhege & Karl Heinz Schleis. From 2007 to 2010, Karl-Martin Hartmann (*1948) designed the window in the ruins of the Bacharach Werner Chapel, which was designed as a memorial, to be visible from afar with the text from Heinrich Heine's Rabbi von Bacherach. Further sections will be shown in Bacharach.
</Text> 
}
{engl?
<Text id="P2" variant='solid'>
<span style={{fontWeight:800}}>2  Frieder Schwitzgebel:</span> Toleranz vor Augen. Bacharach 2010. Leihgabe von Walter Karbach. Frieder Schwitzgebel hat in Zusammenarbeit mit dem Bauverein das Projekt Toleranz vor Augen dokumentiert, in dem auch die Vorträge namhafter Referenten enthalten sind. Ein Meilenstein auf dem langen Weg des Umdenkens.
</Text>:
<Text id="P2" variant='solid'>
<span style={{fontWeight:800}}>2 Frieder Schwitzgebel: </span>Toleranz vor Augen. Das Projekt von Karl-Martin Hartmann in der Wernerkapelle Bacharach in Zusammenarbeit mit dem Bauverein Wernerkapelle. Mainz 2010. Not only the idea and structure of Hartmann's installation are documented, but also the lectures held at the 2008/09 forum as part of the project.

</Text>
}
{engl?
<Text id="P3" variant='solid'>
<span style={{fontWeight:800}}>3 Peter Molitor:</span> Der Knabe Werner, Öl auf Holz, Altarbild, 1845, zeitgenössisch gerahmt. Leihgabe der Pfarrei Oberwesel. Pastor Anton Klütsch gab das Altarbild 1845 bei dem jungen Koblenzer Maler Peter Molitor (1821–1898) vor der Wiedereröffnung der Wernerkapelle in Auftrag. Es zeigt den Schutzpatron der Stadt und Pfarrei als Märtyrer der Eucharistie, schwebend über seiner Stadt. Im Jahre 2012 wurde das Bild auf Initiative des Pastors Bernhard Jacobs durch ein Porträt der neuen Patronin Mutter Rosa ersetzt (s. Stellwand).
</Text>:
<Text id="P3" variant='solid'>
<span style={{fontWeight:800}}> 3 Peter Molitor: Der Knabe Werner</span>, oil on wood, altarpiece, 1845, contemporary frame, loan from the parish of Oberwesel. Pastor Anton Klütsch commissioned the young Koblenz painter Peter Molitor (1821–1898) to paint this altarpiece. It was installed in 1845 on the altar of Oberwesel's Werner Chapel (today: Mother Rosa Chapel). It shows Werner as a martyr of the Eucharist, hovering over his town. In 2012, on the initiative of Pastor Bernhard Jacobs, it was replaced by a Mother Rosa portrait.
</Text>
}
{engl?
<Text id="P4" variant='solid'>
<span style={{fontWeight:800}}>4 Bernhard Scherer:</span> [Das Martyrium des Guten Werner], Altarbild, Öl auf Holz, 1938. Mit einer Reproduktion der historischen Vorlage. Leihgabe der Pfarrei Bacharach. Bernhard Scherer (1898–1951), ein Kirchenmaler aus Weiler über Bingerbrück, hat das Auftragsbild 1938 (einige Monate vor der Reichspogromnacht) für einen Seitenaltar der Nikolauskirche Bacharach gemalt, wo es am Wernertag aufgestellt wurde. Es ersetzte ein älteres ähnliches Bild. Es zeigt wie dieses die Vorbereitung der Marterung durch zwei Juden. Durch das Fenster ist nun aber die Oberweseler Liebfrauenkirche zu sehen, die es allerdings 1287 noch nicht gab. Das Altarbild, an dem sich der Maler orientierte, ist nur als historisches Foto erhalten.
</Text>
:
<Text id="P4" variant='solid'>
<span style={{fontWeight:800}}>4 Bernhard Scherer: </span>[The Martyrdom of Good Werner], Altarpiece, oil on panel, 1938, loan from the parish of from Bacharach. Bernhard Scherer (1898–1951), a church painter from Weiler near Bingerbrück, painted the picture for a side altar in the Nikolaus Church in Bacharach, where it was set up on Werner Day. It shows the preparation of the martyrdom. Through the window you can see the Oberwesel Liebfrauenkirche, which, however, was not yet built in 1287.
</Text>
}
{engl?
<Text id="P5" variant='solid'>
<span style={{fontWeight:800}}>5 S. WERNHERE PATRON UNSSER STAT DER ARMEN UND VOR OBRICHKEIT KLAGNDEN BITT.</span> Reproduktion des Ritualmordreliefs von 1727, Originalgröße. Das Sandsteinrelief von 1727 befand sich bis 1970 am Chor der Oberweseler Wernerkapelle und war in eine Ädikula gefasst. Es stellt den angeblichen Ritualmord im April 1287 an dem Christenjungen Werner dar. Links oben erkennt man die christliche Magd und den von ihr geholten korrupten Schultheißen. Die Umschrift bittet den Schutzpatron um seine Fürbitte. Mehrfach hat sich das antisemitische Hetzblatt Der Stürmer des Motivs bedient. Bürgermeister Dr. Ottendorff hat 1938 dem Stürmer berichtet, wie er auswärtigen »Parteigenossen« das Steinbild erklärt hat.
</Text>
:
<Text id="P5" variant='solid'>
<span style={{fontWeight:800}}>5 S. WERNHERE PATRON UNSSER STAT DER ARMEN UND VOR OBRICHKEIT KLAGNDEN BITT </span>, Reproduction of the ritual murder relief of 1727 in its original size. The sandstone relief, created in 1727, was for centuries on the choir of Oberwesel's Werner Chapel (today: Mother Rosa Chapel). It was set in an aedicule. It depicts the alleged ritual murder in April 1287 of the Christian boy Werner. At the top left you can see the Christian maid and the corrupt judge who had been summoned. The inscription asks the patron saint for intercession. The anti-Semitic weekly Der Stürmer has used it several times. Under pressure from the Central Council of Jews, it was removed in 1970 after considerable resistance. It was brought to the mostly locked Michael Chapel in Oberwesel
</Text>
}
{engl?
<Text id="P6" variant='solid'>
<span style={{fontWeight:800}}>6 Leserbrief des Stadtbürgermeisters Dr. Dr. Walther Ottendorff</span> an das antisemitische Wochenblatt Der Stürmer, Nr. 7, Febr. 1938. Reproduktion. Leihgabe von Walter Karbach. Dr. Dr. Walther Ottendorff (1902–1985) war von 1933–1938 Stadtbürgermeister von Oberwesel. Er schrieb im Februar 1938 dem Stürmer:  »Dieser Knabe Werner ist [...] kein anderer als der deutsche Mensch, den der Jude, wo er nur konnte, gequält und gemartert hat, bis in jüngster Zeit die weltgeschichtliche Tat eines Mannes, Adolf Hitler, seinem Wirken in Deutschland ein Ende gesetzt hat.« 
</Text>
:
<Text id="P6" variant='solid'>
<span style={{fontWeight:800}}>6 Letter from the mayor of Oberwesel, Dr. Dr Walther Ottendorff</span>, to the anti-Semitic weekly Der Stürmer, No. 7, February 1938, loan from Walter Karbach. Dr Dr Walther Ottendorff (1902–1985) was mayor of Oberwesel from 1933–1938. In February 1938 he wrote to Der Stürmer: »This boy Werner [...] is none other than the German man whom the Jew tormented and martyred wherever he could until very recently, the world-historical deed of a man, Adolf Hitler, put an end to his work in Germany.« 
</Text>
}
{engl?
<Text id="P7" variant='solid'>
<span style={{fontWeight:800}}>7  Das Ritualmordrelief nach der Demontage 1970.</span> Reproduktion eines Fotos aus der Rhein-Zeitung vom 16./17. April 1970. Leihgabe von Walter Karbach. Nach 243 Jahren wurde das antijüdische Ritualmordrelief am 15. April 1970 von der Stirnseite der Wernerkapelle auf Druck des Zentralrats der Juden entfernt. Der Bischöfliche Generalvikar Prof. Linus Hofmann schrieb am 6. April 1970: »Ich fordere Sie daher hierdurch auf, [...] dem Werner-Relief einen anderen, der Öffentlichkeit nicht zugänglichen Platz zu geben. Ich schlage vor, daß das Relief dem Diözesan-Museum in Trier als Leihgabe überlassen wird.« Dazu kam es nicht. Das Relief steht seither in der Oberweseler Michaelskapelle.
</Text>
:
<Text id="P7" variant='solid'><span style={{fontWeight:800}}>7 The ritual murder relief after dismantling in 1970.</span> Reproduction of a photo from the Rhein-Zeitung from April, 16/17, 1970. Loan from Walter Karbach. After 243 years, the anti-Jewish ritual-murder relief was removed from the front of the Werner Chapel on April 15, 1970 under pressure from the Central Council of Jews. The Episcopal Vicar General Linus Hofmann wrote on April 6, 1970: »I hereby request you [...] to give the Werner relief another place that is not accessible to the public. I propose that the relief be loaned to the Diocesan Museum in Trier.« It didn't come to that. The relief has been in Oberwesel's Michael chapel ever since.

</Text>
}
{engl?
<Text id="P8" variant='solid'>
<span style={{fontWeight:800}}>8 Zelebrationskelch mit Ritualmorddarstellung, Gold und Silber, 1753.</span> Leihgabe der Pfarrei Dickenschied. Der liturgische Kelch mit der Darstellung des angeblichen Ritualmordes an dem Guten Werner wurde 1753 für den Karmeliterpater Reinerus angefertigt. Er stammt aus der früheren Simultankirche von Womrath, Werners angeblichem Geburtsort, wo es bis heute eine Wernerkapelle gibt
</Text>
:
<Text id="P8" variant='solid'><span style={{fontWeight:800}}>8 Celebration chalice with ritual libel</span>, gold and silver, 1753, loan from the parish of Dickenschied. The liturgical chalice depicting the ritual murder of Good Werner was made in 1753 for the Carmelite Father Reinerus. It comes from the former simultaneous church in Womrath, Werner's alleged place of birth, where there is a Werner chapel to this day.

</Text>
}
{engl?
<Text id="P9" variant='solid'>
<span style={{fontWeight:800}}>9 [Das Martyrium des Guten Werner], </span>Holzfigur, ca. 1728, farbig gefasst. Leihgabe der Pfarrei Oberwesel. Die barocke Ritualmordskulptur, gestaltet nach dem Steinrelief an der Oberweseler Wernerkapelle, stand 238 Jahre lang auf dem Hochaltar der Pfarrkirche St. Martin. Sie wurde 1966 in der Amtszeit von Pastor Adolf Vogt entfernt. Im Inneren der Martersäule werden angebliche Reliquien aus dem Bacharacher Wernergrab vermutet, die nach ihrer Bergung durch den Jesuiten Pater Thomas Sailly 1621 nach Mainz gelangt seien.  1925 sollen zwei Mainzer Jesuitenpatres sie nach Oberwesel gebracht und dort hineingelegt haben.
</Text>
:
<Text id="P9" variant='solid'>
<span style={{fontWeight:800}}>9 [The Martyrdom of the Good Werner],</span> wooden figure, around 1728, painted, loan from the parish of Oberwesel. The baroque ritual murder sculpture, designed after the stone relief on the Oberwesel Werner chapel, stood on the high altar of the parish church of St. Martin for 238 years. It was removed in 1966 during Pastor Adolf Vogt's tenure. Alleged relics from the grave of Werner in Bacharach are believed to be inside the column of torture, which came to Mainz after they had been salvaged by the Jesuit Father Thomas Sailly in 1621. In 1925, two Jesuit priests from Mainz are said to have brought them to Oberwesel and laid them there.

</Text>
}
{engl?
<Text id="P10" variant='solid'>
<span style={{fontWeight:800}}>10 Ansichtskarte vom Hochaltar der St.-Martinskirche von Oberwesel </span>mit Ritualmordskulptur, um 1960. Leihgabe von Walter Karbach. Die barocke Ritualmordskulptur von 1728 stand bis 1966 über dem linken Durchgang des Hochaltars der Pfarrkirche St. Martin. Sie wurde auf Initiative von Pastor Adolf Vogt ohne großes Aussehen entfernt.
</Text>
:
<Text id="P10" variant='solid'>
<span style={{fontWeight:800}}>10 High altar of St Martin's Church with ritual murder sculpture</span>, postcard from the 1960s, loan from Walter Karbach. Until 1966, the baroque ritual murder sculpture stood above the left passage of the high altar of the parish church of St. Martin, Oberwesel.
</Text>
}
{engl?
<Text id="P11" variant='solid'>
<span style={{fontWeight:800}}>11 Säulenreliquiar, Holz, vermutlich 18. Jh., ursprünglich vergoldet. </span>Leihgabe der Pfarrei Oberwesel. Hinter der Glasscheibe zeigt sich ein mit Leintuch umwickeltes und kunstvoll verschnürtes Holzstück der angeblichen Martersäule, die den Pilgern gezeigt wurde. Das Reliquiar stammt aus der Oberweseler Wernerkapelle. Pilger sollen von der Säule wunderwirksame Holzsplitter geschnitten haben. Der Dechant des Liebfrauenstifts, Niklas Sebelin, berichtet 1579 nach Trier, die Säule sei in einer Lade gefasst, die neben dem Altar stehe. Sie sei mit einem Bild Werners und einer Inschrift versehen. 
</Text>:
<Text id="P11" variant='solid'>
<span style={{fontWeight:800}}>11 Column reliquary with pedestal, wood, probably 18th century</span>, originally gilded, loan from the parish of Oberwesel. Behind the pane of glass is a wrapped and artfully tied piece of wood from the alleged torture column that was shown to pilgrims. The reliquary comes from the Oberwesel Werner Chapel (today: Mother Rosa Chapel). The reliquary comes from the Oberwesel Werner Chapel (today: Mother Rosa Chapel). Pilgrims are said to have cut miraculous splinters of wood from the pillar. The dean of the Liebfrauenstift, Niklas Sebelin, reported to Trier in 1579 that the column was held in a drawer that stood next to the altar. It was provided with a picture of Werner and an inscription.

</Text>
}
{engl?
<Text id="P12" variant='solid'>
<span style={{fontWeight:800}}>12 Prozessionsfigur, Weichholz, vermutlich 19. Jh.,</span> ursprünglich vermutlich farbig gefasst. Leihgabe der Pfarrei Oberwesel. Die Figur aus dem 19. Jh. wurde von ausgewählten Jugendlichen bei der alljährlichen Wernerprozession im April durch die geschmückten Straßen der Stadt getragen.
</Text>:
<Text id="P12" variant='solid'>
<span style={{fontWeight:800}}>12 Werner procession figure, softwood, probably 19th century</span>, probably originally painted in colour, loan from the parish of Oberwesel. The figure was carried through the city by selected young people at the annual Werner procession in April.
</Text>
}
{engl?
<Text id="P13" variant='solid'>
<span style={{fontWeight:800}}>13 St. Werner, Weichholz, 19. Jh.,</span> farbig gefasst. Leihgabe der Pfarrei Oberwesel. Die unspektakuläre Wernerfigur aus dem 19. Jh. befand sich bis 1984 in der Oberweseler Kalvarienbergkapelle.

</Text>
:
<Text id="P13" variant='solid'>
<span style={{fontWeight:800}}>13 St Werner, wooden figure,</span> 19th century, painted, loan from the parish of Oberwesel. The unspectacular Werner figure was in the chapel on the Calvary of Oberwesel until 1984.


</Text>
}
{engl?
<Text id="P14" variant='solid'>
<span style={{fontWeight:800}}>14 Domenico Quaglio: Wernerkapelle. </span> Lithografie 1819. Stadtmuseum Oberwesel. Der königlich-bayerische Hofmaler Domenico Quaglio (1787–1837), bekannt für seine Architekturdarstellungen, zeichnete 1819 diese Prozession von Mönchen unter der Oberweseler Wernerkapelle. Allerdings gab es zu dieser Zeit keine Mönche mehr in Oberwesel. Der düstere Stahlstich fand u.a. in dem Buch Merkwürdige Gebäude des teutschen Mittelalters nach der Natur und auf Stein (1850) weite Verbreitung.
</Text>
:
<Text id="P14" variant='solid'>
<span style={{fontWeight:800}}>14 Domenico Quaglio: Werner Chapel</span>, lithograph 1819, City Museum of Oberwesel. The royal Bavarian court painter Domenico Quaglio (1787–1837), well known for his architectural depictions, drew this procession of monks under Oberwesel’s Werner Chapel in 1819. At that time, however, there were no more monks in Oberwesel. This steel engraving was widely used in the book Merkwürdige Gebäude des teutschen Mittelalters (Strange buildings of the German Middle Ages based on nature and on stone), 1850.
</Text>
}
{engl?
<Text id="P15" variant='solid'>
<span style={{fontWeight:800}}>15 Alfred von Osterroth:</span> Wernerkapelle von Oberwesel. Aquarell, Ende 19. Jh., modern gerahmt. Leihgabe von Doris Spormann, und Fotografie des Originals von Carl Haag, Stadtmuseum Oberwesel. Alfred von Osterroth (1884–1953), der Zwillingsbruder Arthur von Osterroths auf Gut Schönberg, betätigte sich gelegentlich als Maler. Bei diesem Bild handelt es sich um eine Kopie eines Aquarells des Hofmalers Carl Haag (1822–1915), den Osterroth persönlich kannte.
</Text>
:
<Text id="P15" variant='solid'>
<span style={{fontWeight:800}}>15 Alfred von Osterroth: </span>The Werner Chapel of Oberwesel, watercolor, late 19th century, modern frame, loan from Doris Spormann. Alfred von Osterroth (1884–1953), Arthur von Osterroth’s twin brother at Gut Schönberg, occasionally worked as a painter. This picture is a copy of the watercolor by the court painter Carl Haag (1822–1915), whom the brothers knew personally. The original watercolor was in the possession of the Osterroth family for a long time.
</Text>
}
{engl?
<Text id="P16" variant='solid'>
<span style={{fontWeight:800}}>16 Carl Haag: Chapel on the Rhine. 1850.</span> Fotografie des Originals. Stadtmuseum Oberwesel. Der in Erlangen 1821 geborene Maler Carl Haag, der viele Jahre für Queen Viktoria tätig war, lebte in dem von ihm zum Wohnsitz ausgebauten Turm in Oberwesel, wo er 1915 starb. Sein Aquarell zeigt die Wernerprozession beim Unterqueren der Wernerkapelle von Norden her. Die Treppe befindet sich tatsächlich auf der Südseite. Alfred von Osterroth (1884–1953) hat dieses Aquarell aus seinem Besitz kopiert.
</Text>:
<Text id="P16" variant='solid'>
<span style={{fontWeight:800}}>16 Carl Haag: </span>Chapel on the Rhine. 1850. Photograph of the original. City Museum of Oberwesel. The painter Carl Haag, born in Erlangen in 1821, who worked for Queen Victoria for many years, lived in the tower in Oberwesel, which he converted into a residence, where he died in 1915. His watercolor shows the Werner procession crossing under the Werner chapel from the north. The staircase is actually on the south side.
</Text>
}
{engl?
<Text id="P17" variant='solid'>
<span style={{fontWeight:800}}>17 Zeittafel</span> zum Kult um Werner von Oberwesel
</Text>
:
<Text id="P17" variant='solid'>
<span style={{fontWeight:800}}>17 Timetable </span>for the cult of the Good Werner of Oberwesel, created by Walter Karbach.

</Text>
}
{engl?
<Text id="P18" variant='solid'>
<span style={{fontWeight:800}}>18 Die Opfer des „Guten Werner“.</span> Landkarte nach: Germania Judaica. Nach dem Leichenfund bei Bacharach im April 1287 brach der Mob nach Oberwesel auf, um die angeblichen Mörder zu massakrieren. In Bacharach gab es schon seit 1284 keine Juden mehr. Die Pogrome breiteten sich 1287/88 wie ein Lauffeuer in der Region aus.
</Text>
:
<Text id="P18" variant='solid'>
<span style={{fontWeight:800}}>18 The victims of "Good Werner".</span> Map from: Germania Judaica II. After the corpse was found near Bacharach in April 1287, the mob set out for Oberwesel to massacre the alleged murderers. The anti-Jewish pogroms spread like wildfire in the Middle Rhine in 1287/88.
</Text>
}
{engl?
<Text id="P19" variant='solid'>
<span style={{fontWeight:800}}>19 Der Stürmer. Deutsches Wochenblatt zum Kampfe um die Wahrheit.</span> Ritualmord-Nummer, Nr. 20, Mai 1939, Reproduktion. Leihgabe von Walter Karbach. Julius Streicher, der Herausgeber des Stürmer, wurde 1946 wegen Verbrechen gegen die Menschlichkeit zum Tode durch den Strang verurteilt und hingerichtet. Im Nürnberger Kriegsverbrecherprozess kam auch die Abbildung des Oberweseler Ritualmordreliefs zur Sprache.
</Text>
:
<Text id="P19" variant='solid'>
<span style={{fontWeight:800}}>19 Der Stürmer.</span> Deutsches Wochenblatt zum Kampfe um die Wahrheit. Ritualmord-Nummer, No. 20, May 1939, reproduction. The editor of Der Stürmer, Julius Streicher, was sentenced to death by hanging for crimes against humanity and executed in 1946. The depiction of the Oberwesel ritual murder relief was also discussed in the Nuremberg war crimes trial.
</Text>
}
{engl?
<Text id="P20" variant='solid'>
<span style={{fontWeight:800}}>20 Volkssage vom heiligen Werner.</span> In: Germaniens Völkerstimmen. Sammlung der deutschen Mundarten, hrsg. von Johannes Matthias Firmenich. Berlin 1854. Reproduktion. Der Bonner Germanistik-Professor Firmenich ließ sich Mitte des 19. Jahrhunderts die Wernergeschichte auf Weseler Platt erzählen, vermutlich von einem Oberweseler Schullehrer.
</Text>
:
<Text id="P20" variant='solid'>
<span style={{fontWeight:800}}>20 Volkssage vom heiligen Werner. </span>In: Germaniens Völkerstimmen. Collection of German dialects, edited by Johannes Matthias Firmenich. Berlin 1854. In the middle of the 19th century, Professor Firmenich from Bonn had the Werner story told to him by a man from Oberwesel on dialect.
</Text>
}
{engl?
<Text id="P21" variant='solid'>
<span style={{fontWeight:800}}>21 Herbstwanderung. Judenfeindliche Karikatur</span> auf dem Titelblatt der antisemitischen Wochenschrift »Der Stürmer«, Nr. 43, November 1936. Reproduktion. Die Zeichnung des NS-Karikaturisten Fips (Philipp Rupprecht) zeigt ein BDM-Mädel komt auf seiner Wanderung bei Oberwesel, das sich freut: »Mein Deutschland, wie bist du so schön, Wenn weit und breit kein Jud zu seh’n.« Bis Ende 1936 hatten die Familien von Ferdinand und Isidor Gerson Oberwesel verlassen. Die Familien Mayer, Frenkel, Marx, Gerson und Gottschalk lebten noch hier.
</Text>
:
<Text id="P21" variant='solid'>
<span style={{fontWeight:800}}>21 Anti-Semitic caricature "Autumn Hike"</span> on the cover of the anti-Semitic weekly "Der Stürmer", No. 43, November 1936. A girl of the League of German Girls (BDM) walks past Oberwesel and is happy that »there is not a Jew to be seen far and wide«.
</Text>
}
{engl?
<Text id="P22" variant='solid'>
<span style={{fontWeight:800}}>22 Programmheft zur Fahnenweihe der Deutschen Jugendkraft (DJK) Oberwesel </span>, Wernerstag-Sonntag, 22. April 1928 und ein zeitgenössisches Foto. Reproduktionen. Der Sportverein DJK bekam 1928 eine Wernerfahne, die am Wernertag in der Martinskirche geweiht wurde. Zum Festprogramm gehörte auch die traditionelle Wernerprozession. Das Programmheft enthält die genaue Ordnung zur Aufstellung der Vereine und Gläubigen. Der Wernertag wurde 1963 aus dem Bistumskalender getilgt. Die letzte Wernerprozession zog 1971 durch Oberwesel.
</Text>
:
<Text id="P22" variant='solid'>
<span style={{fontWeight:800}}>22 Program booklet for the flag consecration of the sports club Deutsche Jugendkraft Oberwesel</span>, on Wernerstag Sunday, April 22, 1928. In 1928, the sports club DJK received a Werner flag, which was consecrated in the Martinskirche. The festival program also included the traditional Werner procession, for which the program contains a precise order for the line-up of the associations and believers.
</Text>
}
{engl?
<Text id="P23" variant='solid'>
<span style={{fontWeight:800}}>23 Erstkommunionsbild, ca. 1950, </span>mit einem Motiv von Hans Franke: Sankt Werner. Farbdruck, zeitgenössisch gerahmt. Leihgabe von Fritz Stüber. Geschenk zum Andenken an die erste Hl. Kommunion. 
Die Darstellung folgt einem Gemälde des Freiburger Malers Hans Franke (1892–1975), gemalt 1923 für einen Oberweseler Auftraggeber. Das Motiv wurde auch als Andachtsbildchen verbreitet. Es wurde 1939 im antisemitischen Stürmer abgebildet.

</Text>
:
<Text id="P23" variant='solid'>
<span style={{fontWeight:800}}>23 Hans Franke: Saint Werner </span>, color print after an oil painting, around 1950, in a contemporary frame, loan from Fritz Stüber. It is a framed gift to commemorate First Holy Communion. The depiction follows a painting by the Freiburg painter Hans Franke (1892–1975), which he painted in 1923 for a client from Oberwesel. At the bottom left you can see two »Jews«. It was also sold as a devotional picture. It was depicted in 1939 in the anti-Semitic weekly Der Stürmer.

</Text>
}
{engl?
<Text id="P24" variant='solid'>
<span style={{fontWeight:800}}>24 Oberweseler Wernerlied,</span> Kopie der Originalpartitur, signiert von Werner Botens. Leihgabe von Werner Klockner. Das Wernerlied wird zu Unrecht dem Pastor Rudolf Wies zugeschrieben, der von 1921 bis zu seinem Tod 1959 in Oberwesel tätig war. Text und Melodie stammen indessen von Werner Botens, geb. 1922 in Oberwesel, von Pastor Wies stammt der Satz für die Blaskapelle. Das Lied wurde in der Schule einstudiert, die Blaskapelle spielte es bei der Wernerprozession. Es verherrlicht den Stadtheiligen als reinen und starken jugendlichen Helden.

</Text>
:
<Text id="P24" variant='solid'>
<span style={{fontWeight:800}}>24 The Oberwesel Werner Hymn,</span> copy of the original score signed by Werner Botens. loan from Werner Klockner. The Wernerlied is wrongly attributed to the pastor Rudolf Wies, in Oberwesel from 1921 until his death in 1959. Text and melody are by Werner Botens (Oberwesel 1922–1991 Hamburg). The song was rehearsed at school and the brass band played it at the Werner procession. It glorifies the patron as a pure and strong youthful hero.
</Text>
}
{engl?
<Text id="P25" variant='solid'>
<span style={{fontWeight:800}}>25 Amateurfotos der Oberweseler Wernerprozession</span> aus den Jahren 1926, 1928, 1940, 1953 und 1958. Reproduktionen nach Leihgaben von Werner Klockner und Walter Karbach. Die Fotos zeigen, wie 1926 die Prozession durch die Koblenzer Straße zieht, wie sie 1928 die Synagoge auf dem Schaarplatz passiert und wie sie 1953 am St.-Werner-Krankenhaus eintrifft. 1958 ist die Synagoge geschmückt mit Kirchenfähnchen. Obwohl 1963 der Wernertag aus dem Bistumskalender getilgt war, zog die Wernerprozession bis 1971 durch die Stadt.
</Text>
:
<Text id="P25" variant='solid'>
<span style={{fontWeight:800}}>25 Amateur photos of the Oberwesel Werner</span> procession from the years 1926, 1928, 1940, 1953 and 1958. Reproductions on loan from Werner Klockner and Walter Karbach. The photos show how the procession went through Koblenzer Strasse in 1926, how it passed the synagogue on Schaarplatz in 1928 and how it arrived at the St. Werner Hospital in 1953. In 1958 the synagogue was decorated with church flags. Although Werner Day was removed from the diocese calendar in 1963, the Werner procession passed through the town until 1971.
</Text>
}
{
  engl?

<Text id="P26" variant='solid'>
<span style={{fontWeight:800}}>26 Litanei zum hl. Werner.</span> In: Andachts-Büchlein für die Pfarrei Oberwesel zur Verehrung und Anrufung des hl. Märtyrers Werner. Bonn 1906. Leihgabe von Werner Klockner. In der Litanei, die bei der Wernerprozession gebetet wurde, wird Werner als »Vorbild des kindlichen Alters und der Jugend« und als »Lilie der Keuschheit und Jungfräulichkeit« gepriesen, der heldenmütig gekämpft und standhaft über die »Grausamkeit der Juden« obsiegt habe.
</Text>
:
<Text id="P26" variant='solid'>
<span style={{fontWeight:800}}>26 Litany to St Werner.</span> In: Andachts-Büchlein für die Pfarrei Oberwesel zur Verehrung und Anrufung des hl. Märtyrers Werner. [Devotional booklet for the parish of Oberwesel for the veneration and invocation of St Martyr Werner.] Bonn 1906. On loan from Werner Klockner. In the litany prayed at the Werner procession, Werner is praised as a "model of childhood and youth" and a "lily of chastity and virginity," who fought heroically and steadfastly triumphed over the "cruelty of the Jews."
</Text>
}
{engl?
<Text id="P27" variant='solid'>
<span style={{fontWeight:800}}>27 WERNERVS MARTYR,</span> Das ist / Ein Lust= unnd Trawriges Schawspil. Kopie des Titelblatts der Perioche. Ingolstadt 1630. Über 200 Schüler der Congregatio major des Jesuitenkollegs Ingolstadt (Abb.) führten im Oktober 1630 das Schauspiel Wernerus Martyr in ihrem Theater auf, zu Ehren des Statthalters Werner Tserclaes, Graf von Tilly. Das Jesuitentheater setzte auf grelle Bühneneffekte und großes Spektakel. Das Schauspiel besteht aus fünf Akten und spielt teilweise in Oberwesel. In V.4 heißt es, die Juden seien, »indem sie aber den Namen JEsu wider höreten, [...] wider rasent und tobent, binden jhn ans Creutz, durchstechen jhn mit Meßern.«
</Text>
:
<Text id="P27" variant='solid'>
<span style={{fontWeight:800}}>27 WERNERVS MARTYR,</span> Das ist / Ein Lust= unnd Trawriges Schawspil. Ingolstadt 1630. In October 1630, more than 200 students from the Jesuit College of Ingolstadt performed the two-day play “Wernerus Martyr” in honor of the governor Werner Tserclaes, Count of Tilly, in their theater, which had been specially written
</Text>
}
{engl?
<Text id="P28" variant='solid'>
<span style={{fontWeight:800}}>28 H: Wernerús ein Júnger baúren sohn zú wesel ist von Júden gefangen</span>, hart gepeiniget, und am H. Charfreytag Christo zú spoth gecreúziget worden. 19 April. Bolland. Verehret von baúren 1711. Öl auf Leinwand, um 1711. Verkleinerte Reproduktion. Leihgabe von Walter Karbach. Das untypische Wernerbild befindet sich im Jüdischen Museum Berlin. Es wird Jan Frans van Douven zugeschrieben, der es vermutlich um 1711 im Auftrag des Kurfürsten Johann Wilhelm II. von Pfalz-Neuburg gemalt hat. Werner, wie Christus ein Kreuz tragend, präsentiert als Patron eines unbekannten Namensträgers den fürstlichen Hubertus-Orden.
</Text>
:
<Text id="P28" variant='solid'>
<span style={{fontWeight:800}}>28 H: Wernerús ein Júnger baúren sohn zú wesel ist von Júden gefangen</span>, hart gepeiniget, und am H. Charfreytag Christo zú spoth gecreúziget worden. 19 April. Bolland. Verehret von baúren 1711. Öl auf Leinwand, um 1711. Verkleinerte Reproduktion. Leihgabe von Walter Karbach. Das untypische Wernerbild befindet sich im Jüdischen Museum Berlin. Es wird Jan Frans van Douven zugeschrieben, der es vermutlich um 1711 im Auftrag des Kurfürsten Johann Wilhelm II. von Pfalz-Neuburg gemalt hat. Werner, wie Christus ein Kreuz tragend, präsentiert als Patron eines unbekannten Namensträgers den fürstlichen Hubertus-Orden.
</Text>
}
{engl?
<Text id="P29" variant='solid'>
<span style={{fontWeight:800}}>29 Titelblatt der Handschrift Vita sancti Wernheri Winands von Steeg, 1429. </span>Schatzkammer der Wissenschaftlichen Bibliothek Trier. Reproduktion. Winands prächtige Handschrift ist das Ergebnis des 1426 von Kardinal Giordano Orsini veranlassten Bacharacher Untersuchungsprozesses zur Heiligkeit des Knaben Werner. Sie war für Papst Martin V. bestimmt, hat Rom aber nie erreicht. S. Wernherus steht, obwohl nicht kanonisiert, mit Nimbus und Märtyrerpalme, Messer, Hacke und Trog, zwischen den beiden Heiligen Andreas und Cunibert, eine ganz besondere Hervorhebung.
</Text>
:
<Text id="P29" variant='solid'>
<span style={{fontWeight:800}}>29 Titelblatt der Handschrift Vita sancti Wernheri Winands von Steeg, 1429. </span>Schatzkammer der Wissenschaftlichen Bibliothek Trier. Reproduktion. Winands prächtige Handschrift ist das Ergebnis des 1426 von Kardinal Giordano Orsini veranlassten Bacharacher Untersuchungsprozesses zur Heiligkeit des Knaben Werner. Sie war für Papst Martin V. bestimmt, hat Rom aber nie erreicht. S. Wernherus steht, obwohl nicht kanonisiert, mit Nimbus und Märtyrerpalme, Messer, Hacke und Trog, zwischen den beiden Heiligen Andreas und Cunibert, eine ganz besondere Hervorhebung.
</Text>
}
{engl?
<Text id="P30" variant='solid'>
<span style={{fontWeight:800}}>30 Blatt mit der Initiale P zum Introitus des Werner-Offiziums Winands von Steeg, 1429. </span>Schatzkammer der Wissenschaftlichen Bibliothek Trier. Reproduktion. Der einflussreiche Jurist und Theologe Winand von Steeg, persönlich bekannt mit Papst Martin V. und seinem Legaten Kardinal Orsini, Pastor in Bacharach und Vertrauter des Pfalzgrafen Ludwig III., nahm auch dieses Hochamt in seinen Bericht über den Untersuchungsprozess auf. Jede Seite der Handschrift gestaltete er persönlich.
</Text>:
<Text id="P30" variant='solid'>
<span style={{fontWeight:800}}>30 Blatt mit der Initiale P zum Introitus des Werner-Offiziums Winands von Steeg, 1429. </span>Schatzkammer der Wissenschaftlichen Bibliothek Trier. Reproduktion. Der einflussreiche Jurist und Theologe Winand von Steeg, persönlich bekannt mit Papst Martin V. und seinem Legaten Kardinal Orsini, Pastor in Bacharach und Vertrauter des Pfalzgrafen Ludwig III., nahm auch dieses Hochamt in seinen Bericht über den Untersuchungsprozess auf. Jede Seite der Handschrift gestaltete er persönlich.
</Text>
}
{engl?
<Text id="P31" variant='solid'>
<span style={{fontWeight:800}}>31 Saint Vernier. Holzfigur, Église Saint-Hippolyte, Beure bei Besançon. </span>Reproduktion eines Fotos von Walter Karbach. Der Wernerkult gelangte nach Besançon, als 1548 der Kanoniker Jean Chuppin eine Fingerreliquie aus Bacharach nach Besançon brachte. Aus Werner wurde Vernier. Von Besançon wanderte der Kult um den Patron der Winzer weiter Richtung Süden. Vernier-Figuren finden sich in zahlreichen Pfarrkirchen. Bei guter Ernte begoss man Saint Vernier mit jungem Wein. </Text>
:
<Text id="P31" variant='solid'>
<span style={{fontWeight:800}}>31 Saint Vernier.</span> Wooden figure, Église Saint-Hippolyte, Beure near Besançon. Reproduction of a photo by Walter Karbach. The Werner cult came to Besançon when, in 1548, the canon Jean Chuppin brought a finger relic from Bacharach to Besançon. Werner became Vernier. From Besançon, the cult of the patron saint of winegrowers migrated further south. Vernier figures can be found in numerous parish churches. When the harvest was good, Saint Vernier was watered with young wine. </Text>
}
{engl?
<Text id="P32" variant='solid'>
<span style={{fontWeight:800}}>32 Saint Verny. Holzfigur, Église Saint-Pierre, Beaumont bei Clermont-Ferrand.</span> Reproduktion eines Fotos von Walter Karbach. Von Besançon ausgehend wanderte der Wernerkult nach Süden bis nach Clermont-Ferrand, wo aus Vernier nun der Winzerpatron Verny wird. Auch hier finden wir seine Statue in Pfarrkirchen und auf öffentlichen Plätzen. Bei schlechter Ernte stellte man Saint Verny in den Bach und schlug man ihn mit Brennnesseln.
</Text>:
<Text id="P32" variant='solid'>
<span style={{fontWeight:800}}>32 Saint Verny. Holzfigur, Église Saint-Pierre, Beaumont bei Clermont-Ferrand.</span> Reproduktion eines Fotos von Walter Karbach. Von Besançon ausgehend wanderte der Wernerkult nach Süden bis nach Clermont-Ferrand, wo aus Vernier nun der Winzerpatron Verny wird. Auch hier finden wir seine Statue in Pfarrkirchen und auf öffentlichen Plätzen. Bei schlechter Ernte stellte man Saint Verny in den Bach und schlug man ihn mit Brennnesseln.
</Text>
}
{engl?
<Text id="P33" variant='solid'>
<span style={{fontWeight:800}}>33 René Perrot: Saint Vernier, Patron des Vignerons d’Ornans. Aquarell 1943. </span>Reproduktion. Der Maler René Perrot (1912–1979) war Mitglied der Gruppe Les Rustiques, die für rustikale Naturdarstellungen bekannt ist. Das Aquarell Saint Vernier befindet sich im Musée des civilisations de l’Europe et de la Méditerranée, Marseille. Es zeigt den Heiligen als Schutzpatron der Winzer.
</Text>:
<Text id="P33" variant='solid'>
<span style={{fontWeight:800}}>33 René Perrot: Saint Vernier</span>, Patron des Vignerons d’Ornans, watercolor 1943. René Perrot (1912–1979) was a member of the group »Les Rustiques«, known for its rustic depictions of nature. The watercolor is shown in the Musée des civilizations de l’Europe et de la Méditerranée (MuCem), Marseille.

</Text>
}
{engl?
<Text id="P34" variant='solid'>
<span style={{fontWeight:800}}>34 Plakat zur Ausstellung Les Saint Verny d’Auvergne</span>, Plauzat, 16. Juli – 3. August 2017. Reproduktion. Mit dem Plakat wurde 2017 für die Ausstellung Saint Verny. Le Patron des vignerons auvergnats (St. Werner, Schutzpatron der Winzer der Auvergne). Hier wurden zahlreiche Wernerfiguren aus Pfarrkirchen der Region präsentiert. In der Region finden alljährlich Umzüge der örtlichen Weinbruderschaften statt, die ihre jeweiligen Verny-Statuen mit sich führen.
</Text>
:
<Text id="P34" variant='solid'>
<span style={{fontWeight:800}}>34 Poster for the exhibition Les Saint Verny d’Auvergne</span> , Plauzat 16.07.-03.08.2017. With »Saint Verny. Le Patron des vignerons auvergnats” (St. Werner, patron saint of Auvergne winegrowers) was promoted in 2017 for an exhibition of Werner figures from parish churches in the region.
</Text>
}
{engl?
<Text id="P35" variant='solid'>
<span style={{fontWeight:800}}>35 Website der Dionysiusparochie Heerhugowaard. Ausdruck Mai 2022.</span> Leihgabe von Walter Karbach. Die Dionysiuspfarrei im niederländischen Heerhugowaard präsentiert bis heute die Wernergeschichte in kindgerechter Form mit einer modernen Illustration auf ihrer Website. Aus den Juden sind nun Bauern geworden. »Gib mir die Hostie, schreit der Bauer wütend. Zusammen mit dem Knecht nimmt der Bauer Werner mit und bringt ihn in den Keller. Im Keller stehen Säulen. Sie fesseln ihn kopfüber an eine dieser Säulen. Aber Werner [...] würde lieber sterben, als sie die heilige Hostie verspotten zu lassen. [...] Es ist der 19. April des Jahres 1287. Werner ist also vierzehn Jahre alt. [...] Es wird Werners letzter Tag hier auf der Erde. Da glitzert etwas. Es ist ein Messer. Sie haben damit seine Halsschlagader durchtrennt. Ihr könnt euch vorstellen, was dann geschah.«
</Text>
:

<Text id="P35" variant='solid'>
<span style={{fontWeight:800}}>35 Website ot the Dionysiusparochie Heerhugowaard.</span>   Printout May 2022. Loan from Walter Karbach. The Dionysius parish in Heerhugowaard in the Netherlands still presents the Werner story in a child-friendly form with a modern illustration on its website. The Jews have now become farmers. "Give me the host," the farmer yells angrily. Together with the servant, the farmer takes Werner and to the cellar. There are pillars in the basement. They tie him upside down to one of these pillars. But Werner [...] would rather die than have them mock the Holy Host. [...] It is April 19, 1287. Werner is fourteen years old. [...] It will be Werner's last day here on earth. Something is glittering. It's a knife. They used it to cut his carotid artery. You can imagine what happened next."
</Text>
}
{engl?
<Text id="P36" variant='solid'>
<span style={{fontWeight:800}}>36Arnold Carnot: St. Werner. </span>Kohlestift auf Karton, ca. 1935. Leihgabe der Pfarrei Bacharach. Der Kölner Maler Arnold Carnot fertigte um 1935 im Auftrag der Bacharacher Pfarrei verschiedene Entwürfe für ein Tafelbild in der Nikolauskirche an, die jedoch nicht realisiert wurden. Das Motiv wurde als Ansichtskarte von Knippenberg’s Kunstverlag Düsseldorf vertrieben. In diesem Verlag ist u.a. das antijüdische Machwerk Lied vom Levi erschienen. 
</Text>
:
<Text id="P36" variant='solid'>
<span style={{fontWeight:800}}>36 Arnold Carnot: St Werner </span>, charcoal pencil on cardboard, around 1935, loan from the parish of Bacharach. Around 1935, the Cologne painter Arnold Carnot made various drafts for a Werner panel painting for the Nikolaus Church in Bacharach on behalf of the parish, but these were not realized. However, the motif was distributed as a postcard. 
</Text>
}
{engl?

<Text id="P37" variant='solid'>
<span style={{fontWeight:800}}>37Introitus in festo Werneri.</span> Graduale, vermutlich 17. Jh., Leihgabe der Pfarrei Bacharach. Das Choralbuch der Pfarrei Bacharach stammt wohl aus dem 17. Jh. und enthält Messgesänge zu den Festtagen. Am Wernertag wurde dieser Introitus (Eröffnungsgesang) zu Beginn des Festgottesdienstes gesungen.
</Text>:
<Text id="P37" variant='solid'>
<span style={{fontWeight:800}}>37 Introitus in festo Werneri, gradual, probably 17th century.</span> Loan from the parish of Bacharach. The choral book comes from the parish of Bacharach. The page with the introitus (opening song of the service), sung on Werner Day, is open
</Text>
}
{engl?
<Text id="P38" variant='solid'>
<span style={{fontWeight:800}}>38 1995er Dellhofener St. Wernerberg Riesling Eiswein.</span> Weingut Goswin Lambrich, Dellhofen. Leihgabe von Walter Karbach. Die kleine Weinlage St. Wernerberg liegt unterhalb von Dellhofen. Lagenwein ist heute nicht mehr erhältlich.
</Text>
:
<Text id="P38" variant='solid'>
<span style={{fontWeight:800}}>38 1995er Dellhofener St. Wernerberg Riesling Eiswein</span>, Weingut Goswin Lambrich. The small St. Wernerberg vineyard below Oberwesel-Dellhofen still exists, but vineyard wines are no longer available.
</Text>
}
{
engl?
<Text id="P39" variant='solid'>
<span style={{fontWeight:800}}>39 Saint Vernier Crémant du Jura AOP brut.</span> Leihgabe von Walter Karbach. Dieser Crémant aus Arbois im französischen Jura war 2022 im Onlineshop des Discounters Lidl erhältlich. Das Etikett der Weinkellerei Saint Verny stammt aus dem Winzerort Veyre-Mouton in der Auvergne. 
</Text>
:
<Text id="P39" variant='solid'>
<span style={{fontWeight:800}}>39 Saint Vernier Crémant du Jura AOP brut, loan from Walter Karbach.</span> This crémant from Arbois in the Jura was available in 2022 in the online shop of the discounter Lidl for €11.99. The label of the Saint Verny winery comes from the wine-growing village of Veyre-Mouton in Auvergne. In 1889, after a visit to Oberwesel, the Capuchin Father Henri de Grèzes published in Clermont-Ferrand on the life, martyrdom and cult of Saint Vernier. 
</Text>
}
{engl?
<Text id="P40" variant='solid'>
<span style={{fontWeight:800}}>40 Henri de Grèzes: </span>Saint Vernier (Verny, Werner, Garnier). Martyr. Patron des Vignerons en Auvergne et en Franche-Comté. Sa vie, son Martyre et son Culte. Clermont-Ferrand 1889. Frontpage. Loan from Walter Karbach. In 1889, with the blessing of the Bishop of Clermont, the Capuchin Father Henri de Grèzes published a book on the life, martyrdom, and cult of Saint Vernier, written after a visit to Oberwesel. It also contains the story of the alleged miracle healing of the young Anna Michaely in 1869 in the Werner chapel.
Wine bottle labels from Dellhofen and Bacharach, loans from Walter Karbach. The vinerys Gölz’sche 
</Text>
:
<Text id="P40" variant='solid'>
<span style={{fontWeight:800}}>40 Henri de Grèzes: Saint Vernier (Verny, Werner, Garnier). </span>Martyr. Patron des Vignerons en Auvergne et en Franche-Comté. Sa vie, son Martyre et son Culte. Clermont-Ferrand 1889. Frontpage. Loan from Walter Karbach. In 1889, with the blessing of the Bishop of Clermont, the Capuchin Father Henri de Grèzes published a book on the life, martyrdom, and cult of Saint Vernier, written after a visit to Oberwesel. It also contains the story of the alleged miracle healing of the young Anna Michaely in 1869 in the Werner chapel.
Wine bottle labels from Dellhofen and Bacharach, loans from Walter Karbach. The vinerys Gölz’sche Weinkellerei Bacharach, Goswin Lambrich Dellhofen and Johann Stahl Dellhofen advertised with St Werner.
</Text>
}
{engl?
<Text id="P41" variant='solid'>
<span style={{fontWeight:800}}>41 Weinflaschenetiketten aus Dellhofen und Bacharach.</span> Leihgaben von Walter Karbach. Mit St. Werner warben die Gölz’sche Weinkellerei Bacharach und die Dellhofener Weingüter Goswin Lambrich Dellhofen und Johann Stahl.
</Text>:
<Text id="P41" variant='solid'>
<span style={{fontWeight:800}}>41 Wine bottle labels from Dellhofen and Bacharach</span>, loans from Walter Karbach. The vinerys Gölz’sche Weinkellerei Bacharach, Goswin Lambrich Dellhofen and Johann Stahl Dellhofen advertised with St Werner
</Text>
}
{engl?
<Text id="P42" variant='solid'>
<span style={{fontWeight:800}}>42 Gesang- und Gebetbuch für die Diözese Trier</span>, hrsg. von dem Bischöflichen General-Vikariat. Paulinus-Druckerei Trier 1892. Leihgabe von Walter Karbach. Diese kleine Ausgabe des Gesangbuches wurde zum Preis von 3.60 Reichsmark in der Oberweseler Buchhandlung Josef Karbach, Liebfrauenstraße 56, verkauft. Das Gebet zum Hl. Werner findet sich auf Seite 326.
</Text>
:
<Text id="P42" variant='solid'>
<span style={{fontWeight:800}}>42 Hymn and prayer book for the Diocese of Trier</span>, published by the Episcopal General Vicariate, Paulinus Printing House Trier 1892, loan from Walter Karbach. This small edition of the hymn book without music was sold at the price of 3.60 RM in the Oberwesel bookshop of Josef Karbach. On page 326 there is a prayer to St Werner.
</Text>
}
{engl?
<Text id="P43" variant='solid'>
<span style={{fontWeight:800}}>43 Kladde der Katholischen Jugend Oberwesel, 1946–48.</span> Leihgabe von Walter Karbach. Die Mädchen der Jugendgruppe schrieben reihum Berichte von ihren Ausflügen und Heimabenden. Am Abend des 15. April 1947 ging es vor dem Wernertag im Pfarrhaus bei Pastor Rudolf Wies um den »Schutzpatron der Jugend«. Am Schluss des Heimabends wurde das Wernerlied gesungen
</Text>
:
<Text id="P43" variant='solid'>
<span style={{fontWeight:800}}>43 Report book of the Catholic Youth Oberwesel,</span> 1946-1948, notebook loan from Walter Karbach. The catholic teenage girls reported in turn about their excursions and home evenings. Before Werner’s Day, on the evening of April 15, 1947, they discussed in the vicarage with Pastor Rudolf Wies »St. Werner (…) patron saint of youth«. At the end the Wernerlied was sung
</Text>
}
{engl?
<Text id="P44" variant='solid'>
<span style={{fontWeight:800}}>44 Amateurfotos der Oberweseler Wernerprozession</span> aus den Jahren 1926, 1928, 1940, 1953 und 1958. Reproduktionen nach Leihgaben von Werner Klockner und Walter Karbach. Die Fotos zeigen, wie 1926 die Prozession durch die Koblenzer Straße zieht, wie sie 1928 die Synagoge auf dem Schaarplatz passiert und wie sie 1953 am St.-Werner-Krankenhaus eintrifft. 1958 ist die Synagoge geschmückt mit Kirchenfähnchen. Obwohl 1963 der Wernertag aus dem Bistumskalender getilgt war, zog die Wernerprozession bis 1971 durch die Stadt.
</Text>
:
<Text id="P44" variant='solid'>
<span style={{fontWeight:800}}>44 Amateur photos of the Oberwesel Werner procession</span> from the years 1926, 1928, 1940, 1953 and 1958. Reproductions on loan from Werner Klockner and Walter Karbach. The photos show how the procession went through Koblenzer Strasse in 1926, how it passed the synagogue on Schaarplatz in 1928 and how it arrived at the St Werner Hospital in 1953. In 1958 the synagogue was decorated with church flags. Although Werner Day was removed from the diocese calendar in 1963, the Werner procession passed through the town until 1971.
</Text>
}
{engl?

<Text id="P45" variant='solid'>
<span style={{fontWeight:800}}>45 Andachts-Büchlein für die Pfarrei Oberwesel</span> zur Verehrung und Anrufung des hl. Märtyrers Werner. Bonn 1906. Leihgabe von Werner Klockner. Das Andachtsbüchlein erschien zuerst 1881, es wurde 1906 erneut aufgelegt. Es enthält nicht nur eine Litanei, in der Werner als Patron von Stadt und Pfarrei angerufen wird, sondern auch ein Lied, vermutlich von Pastor Johann Blum, in dem es heißt: »Ward’st an’s Schmerzensholz gebunden / Durch der Juden grause Wut. / Und bedeckt von tausend Wunden / Opferst Du Dein kostbar Blut.« Das Wort »Juden« ist besonders hervorgehoben.
</Text>
:
<Text id="P45" variant='solid'>
<span style={{fontWeight:800}}>45 Andachts-Büchlein für die Pfarrei Oberwesel </span>zur Verehrung und Anrufung des hl. Märtyrers Werner [Devotional booklet for the parish of Oberwesel for the veneration and invocation of St Werner Martyr]. Bonn 1906. Loan from Werner Klockner. The devotional booklet first appeared in 1881 and was reprinted in 1906. It contains not only a litany in which Werner is invoked as the patron saint of the town and parish, but also a song, presumably by the Pastor, which says: »You were tied to the wood of pain / Through the horror of the Jews. / And covered by a thousand wounds / You sacrifice your precious blood.« The word »Jews« is particularly emphasized. 
</Text>
}
{engl?
<Text id="P46" variant='solid'>
<span style={{fontWeight:800}}>46 Lieder für die Katholische Pfarrgemeinde Oberwesel</span>, 1950. Leihgabe von Werner Klockner. Das Oberweseler Pfarramt ließ im Heiligen Jahr 1950 dieses kleine Liederheft drucken, darin steht auch das Oberweseler Wernerlied. Das Heft konnte man ins Gesangbuch einlegen.
</Text>
:
<Text id="P46" variant='solid'>
<span style={{fontWeight:800}}>46 Lieder für die Katholische Pfarrgemeinde Oberwesel, 1950.</span> Loan from Werner Klockner. In the holy year 1950, the Oberwesel parish office had this little book of songs printed, it also contains the Wernerlied. It could be put in the hymn book.
</Text>
}
{engl?
<Text id="P47" variant='solid'>
<span style={{fontWeight:800}}>47 Pater Wilhelm Auer:</span> Illustrierte Heiligenlegende für Schule und Haus. Druck und Verlag von Carl Aug. Seyfried & Co. München. Kirchliche Druckerlaubnis von 1890. 5. Aufl. 1907. Leihgabe von Doris Spormann. Pater Wilhelm Auers Heiligenlegende ist eine von vielen, wie sie in den meisten katholischen Haushalten standen. Die unterschiedlichen Fassungen basieren meist auf dem 1764 erschienenen Werk des Jesuitenpaters Matthäus Vogel und dem in Koblenz 1758 erschienenen Büchlein Kurtze Lebens=Verfassung eines heil. Knabens von 14 Jahren aus unserem Chur-Trierischen Bischthum Nahmens Wernerus.
</Text>
:
<Text id="P47" variant='solid'>
<span style={{fontWeight:800}}>47 Pater Wilhelm Auer</span>: Illustrierte Heiligenlegende für Schule und Haus [Illustrated Legends of Saints for School and Home], 5th edition, Munic 1890, loan from Doris Spormann. Father Wilhelm Auer's legend of Saints is one of many found in most Catholic households. The different versions are mostly based on the work of the Jesuit Father Matthäus Vogel, published in 1764, and a booklet published in Koblenz in 1758.
</Text>
}
{engl?
<Text id="P48" variant='solid'>
<span style={{fontWeight:800}}>48 Hans Hümmeler: Helden und Heilige.</span> Januar bis Juni. Buchgemeinde Bonn. Religiöse Schriftenreihe, Neunter Band. Verlag der Buchgemeinde, Bonn 1933. Leihgabe von Walter Karbach. Die Wernerlegende formulierte Dr. Hans Hümmeler (1899–1966) im Jahre von Hitlers Machtübernahme 1933 in eigener Weise. Hümmeler war Herausgeber und Autor des Werkes, das jahrzehntelang in nahezu jedem katholischen Haushalt stand. Der Text wurde im Laufe der Zeit mehrfach geändert.
</Text>:
<Text id="P48" variant='solid'>
<span style={{fontWeight:800}}>48 Hans Hümmeler: Helden und Heilige [Heroes and Saints.</span> January to June], Buchgemeinde Bonn, religious series, vol. 9, Bonn 1933, loan from Walter Karbach. The Werner legend 1933 according to Dr Hans Hümmeler (1899–1966) was the editor and author of the two-volume work, which was in almost every Catholic household for decades
</Text>}
{engl?
<Text id="P49" variant='solid'>
<span style={{fontWeight:800}}>49 Werner Geiger: Sankt Werner,</span> der Heilige vom Rhein und Hunsrück. Verlag der Druckerei Lehrlingshaus, Mainz 1938. Leihgabe von Werner Dupuis. Werner Geiger (1900–1975) war von 1925 bis 1930 Kaplan in Bingen, ab 1930 Präses des Katholischen Lehrlingshauses Mainz, von 1941 bis 1969 Pfarrer in Lindenfels (Odenwald). Er erklärte 1938 seinen Namenspatron zu einem volksdeutschen Helden. Der Umschlag (Abb.) zeigt den Kopf der Oberweseler Prozessionsfigur.
</Text>:
<Text id="P49" variant='solid'>
<span style={{fontWeight:800}}>49 Werner Geiger: Sankt Werner</span>, der Heilige vom Rhein und Hunsrück, Verlag der Druckerei Lehrlingshaus, Mainz 1938, loan from Werner Dupuis. Werner Geiger (1900–1975) was chaplain in Bingen from 1925 to 1930, from 1930 president of the Catholic Lehrlingshaus (apprenticeship center) in Mainz, from 1941 to 1969 pastor in Lindenfels (Odenwald). In 1938 he declared his patron saint an ethnic German hero.

</Text>
}
{engl?
<Text id="P50" variant='solid'>
<span style={{fontWeight:800}}>50 Andachtsbildchen Heil. Werner.</span> Papier, Farbdruck, um 1900, B. Kühlen’sche Kunstanstalt, Mönchen-Gladbach. Leihgabe von Doris Spormann. Das Andachtsbildchen ist ein Beispiel von vielen: Bernhard Kühlen durfte sich »Verleger des Apostolischen Stuhles« nennen. Auf der Rückseite steht: »Gebet. Herr! wende doch um der Fürbitte des heiligen Werner willen gnädig von uns ab, was uns an Leib und Seele schaden kann. Durch Christum, unsern Herrn. Amen.«
</Text>:
<Text id="P50" variant='solid'>
<span style={{fontWeight:800}}>50 Devotional picture Heil. Werner</span>, color printing, around 1900. B. Kühlen’sche Kunstanstalt, Verleger des Apostolischen Stuhles, Mönchen-Gladbach, loan from Doris Spormann. Prayer on the back: »Lord! for the sake of Saint Werner's intercession, mercifully turn away from us what can harm us in body and soul. Through Christ our Lord. Amen.«
</Text>
}
{engl?
<Text id="P51" variant='solid'>
<span style={{fontWeight:800}}>51 Andachtsbildchen S. Wernherus.</span> M. Papier, Stahlstich, ca. 1820. Leihgabe von Doris Spormann. Andachtsbildchen haben eine lange Tradition. Hier steht auf der Rückseite ein Auszug aus der Heiligenlegende De Probatis Sanctorum Historiis des Kölner Karthäuserpaters Laurentius Surius (1605). Demnach sei Werner »nach dem Exempel Christi umgebracht worden. Sein Leichnam von Juden in eine Höhle geworffen / allda Wernheri Unschuld / und der Juden Grausamkeit durch himmlische Liechter geoffenbahret worden.«
</Text>:
<Text id="P51" variant='solid'>
<span style={{fontWeight:800}}>51 Devotional picture S. Wernherus Puer M</span>, printing, around 1820. Loan from Doris Spormann. On the reverse: Excerpt from the legend of the saint De Probatis Sanctorum Historiis by the Cologne Carthusian monk Laurentius Surius (1605). According to this, Werner “was killed after the example of Christ. His corpse was thrown into a cave by the Jews / where Wernher's innocence / and the cruelty of the Jews was revealed by heavenly lights.«
</Text>
}
{engl?
<Text id="P52" variant='solid'>
<span style={{fontWeight:800}}>52 Stahlstich S. Wernherus Puer M.,</span> 16. Jh., Reproduktion. Leihgabe von Walter Karbach. Ein Beispiel für die Darstellung des angeblichen Ritualmordes durch die Juden. Das lateinische Gebet lautet sinngemäß: »Gott, der Du den Knaben Werner, gestärkt durch die österlichen Sakramente, stärker gemacht hast durch die Folter der Juden, gib, dass wir das Fest der Auferstehung so begehen, dass auch wir es verdienen, die Früchte zu erhalten.« Darunter steht: »Bete für die Bekehrung der Juden.«
</Text>:
<Text id="P52" variant='solid'>
<span style={{fontWeight:800}}>52 Print S. Wernherus Puer M. 18th century</span>. Loan from Walter Karbach. An example of the depiction of the alleged ritual murder by the Jews. The Latin prayer reads: »God, who strengthened the boy Werner through the Easter sacraments and made him stronger through the torture of the Jews, grant that we celebrate the Feast of Resurrection in such a way that we too deserve it, the fruit.” Underneath it is written: “Pray for the conversion of the Jews.” 
</Text>
}
{engl?
<Text id="P53" variant='solid'>
<span style={{fontWeight:800}}>53</span>Stahlstich S. Wernerus, Martyr. 19. April. 19. Jh. (?), Reproduktion. Leihgabe von Doris Spormann. Der Stahlstich zeigt Werner als jungen Erwachsenen, der die Palme der Märtyrer und seine Martersäule in Händen hält. Darunter steht sinngemäß: »Gewähre und, wir bitten dich, Allmächtiger Gott, dass wir, die wir den Geburtstag deines Märtyrers Werner feiern, durch seine Fürbitte in der Liebe zu deinem Namen gestärkt werden.«
</Text>:
<Text id="P53" variant='solid'>
<span style={{fontWeight:800}}>53</span> Devotional picture S. Wernerus, Martyr. 19. April. 19th century. Loan from Doris Spormann. The devotional picture shows Werner as a young adult, holding the palm of the martyrs and his pillar. Below it is written: “Grant and we ask you, Almighty God, that we who are celebrating the birthday of your martyr Werner, will be strengthened in our love for your name through his intercession.”
</Text>
}
{engl?
<Text id="P54" variant='solid'>
<span style={{fontWeight:800}}>54 Plakette St. Werner, </span>Bronze, Kevelaer 2019. Leihgabe von Walter Karbach. Die Plakette wird von Butzon & Bercker im niederrheinischen Wallfahrtsort Kevelaer vertrieben. Sie ist online bestellbar und wird im Geschenkkarton mit Beipackzettel geliefert.
</Text>:
<Text id="P54" variant='solid'>
<span style={{fontWeight:800}}>54 Plaque St Werner, bronze, 2019</span>, loan from Walter Karbach. The plaque is distributed by Butzon & Bercker in the pilgrimage site of Kevelaer. It can be ordered online and is delivered in a gift box with an instruction leaflet.
</Text>
}
{engl?
<Text id="P55" variant='solid'>
<span style={{fontWeight:800}}>55 Jakob Szliska: Der Heilige Werner in Geschichte und Legende.</span> Verlag für Religiöses Schrifttum Dr. Krueckemeyer, Saarbrücken 1953. Leihgaben von Walter Karbach und Doris Spormann. Dr. Jakob Szliska (1893–1968) war von 1923 bis 1945 Schulrat in Saarbrücken, 1941–1945 Referent im Reichsministerium für die besetzten Ostgebiete und Verfasser militärpädagogischer Schriften. In den 1950er Jahren wurden seine mit kirchlicher Druckerlaubnis verbreiteten Heiligenlegenden, illustriert von Max Teschemacher, gerne an Namenstagen verschenkt. In der von der Ritualmordlüge bereinigten Legende wird die Hostienfrevellüge aufrechterhalten.
</Text>:
<Text id="P55" variant='solid'>
<span style={{fontWeight:800}}>55 Jakob Szliska: </span>Der Heilige Werner in Geschichte und Legende, Verlag für Religiöses Schrifttum Dr. Krueckemeyer, Saarbrücken 1953, loan from Walter Karbach. Dr Jakob Szliska (1893–1968) was a school board member in Saarbrücken from 1923 to 1945. From 1941–1945 he was a consultant in the III. Reich Ministry for the occupied Eastern Territories and the author of military pedagogical writings. In the 1950s, his legends of Saints, illustrated by Max Teschemacher and published with church printing permission, were often given away on name days. In the legend cleansed of the ritual murder lie, the host sacrilege lie is maintained.
</Text>
}
{engl?
<Text id="P56" variant='solid'>
<span style={{fontWeight:800}}>56 Alfons Dewald: Der leuchtende Schein. </span>Die Geschichte des heiligen Werner. Johannes-Verlag, Leutesdorf am Rhein 1954. Leihgabe von Doris Spormann. Alfons Dewald (1901–1990) war seit 1950 Pfarrer in Ruhe in Bad Ems, und Verfasser zahlreicher Kinderbücher und katholischer Erbauungsschriften. Seine absonderliche Wernergeschichte erschien 1954 mit kirchlicher Druckerlaubnis. Kopie einer Textstelle (Abb.).
</Text>:
<Text id="P56" variant='solid'>
<span style={{fontWeight:800}}>56 Alfons Dewald: </span>Der leuchtende Schein [The Shining Appearance]. Die Geschichte des heiligen Werner. Leutesdorf 1954, loan from Doris Spormann. Alfons Dewald (1901–1990) had been a pastor in peace in Bad Ems. He is the author of children's books and Catholic devotional writings. His story about Werner was published in 1954 with ecclesiastical permission for printing
</Text>
}
{engl?
<Text id="P57" variant='solid'>
<span style={{fontWeight:800}}>57 Hans Franke: Sankt Werner. </span>In: Erde und Ewigkeit, Bilder von Hans Franke. Breslau. Loan from Walter Karbach. The depiction follows a painting by the Freiburg painter Hans Franke (1892–1975), which he painted 1923 for a client from Oberwesel. The motif was sold as a devotional picture in the 1930s by Photo-Verlag Carl Gehl, Freiburg.
</Text>:
<Text id="P57" variant='solid'>
<span style={{fontWeight:800}}>57 Hans Franke: Sankt Werner.</span> In: Erde und Ewigkeit, Bilder von Hans Franke. Breslau. Loan from Walter Karbach. The depiction follows a painting by the Freiburg painter Hans Franke (1892–1975), which he painted 1923 for a client from Oberwesel. The motif was sold as a devotional picture in the 1930s by Photo-Verlag Carl Gehl, Freiburg.
</Text>
}
{engl?
<Text id="P58" variant='solid'>
<span style={{fontWeight:800}}>58 Claude-Antoine Beau: Saint Vernier, Öl auf Papier, 1835.</span> Musée Gustave Courbet, Ornans. Den später berühmten Maler Auguste Courbet (1819–1877) hat sein Lehrer Claude-Antoine Beau in jungen Jahren vor der Kulisse seines Geburtsortes, des Winzerdorfes Ornans, als Saint Vernier dargestellt.
</Text>:
<Text id="P58" variant='solid'>
<span style={{fontWeight:800}}>58 Claude-Antoine Beau: Saint Vernier,</span> oil on paper, 1835. Musée Gustave Courbet, Ornans. The later famous painter Auguste Courbet (1819–1877) was depicted as Saint Vernier by his teacher Claude-Antoine Beau at a young age against the backdrop of his birthplace, the wine-growing village of Ornans.
</Text>
}
{engl?
<Text id="P59" variant='solid'>
<span style={{fontWeight:800}}>59 Wernerius-Darstellung in der Chapel</span> of the Divine Child der Universität Bethlehem. Die Chapel of the Divine Child (Kapelle des Göttlichen Kindes) der katholischen Universität Bethlehem wurde 1908 erbaut. Der neue Lateinische Patriarch von Jerusalem, der spätere Kardinal Filippo Camassei, weihte sie noch im gleichen Jahr. Sie wurde zum Hauptheiligtum der Erzbruderschaft bestimmt. Die Kapelle ist mit zahlreichen Darstellungen von heiligen Kindern ausgemalt, von denen Werner einer ist. Die Gemälde wurden 1957/58 von Robert Laban restauriert. 
</Text>:
<Text id="P59" variant='solid'>
<span style={{fontWeight:800}}>59 Wernerius depiction in the Chapel of the Divine Child at Bethlehem.</span> The Chapel of the Divine Child at Bethlehem Catholic University was built in 1908. The new Latin Patriarch of Jerusalem, later Cardinal Filippo Camassei, consecrated it in the same year. It was designated as the main sanctuary of the Archconfraternity. The chapel is painted with numerous depictions of holy children, of which Werner is one. The paintings were restored by Robert Laban in 1957/58.

</Text>
}
{engl?
<Text id="P60" variant='solid'>
<span style={{fontWeight:800}}>60 Caput sancti Werneri. </span>Foto einer Schädelkalotte aus Speyer. Die angebliche Schädeldecke den Knaben Werner wurde 1970 bei Ausgrabungsarbeiten in der St.-Afra-Kapelle des Doms zu Speyer in einer Kiste mit Reliquien gefunden, die aus dem Mainzer Domschatz stammt. Sie könnte 1621 zu den Mainzer Jesuiten gelangt sein als Dank dafür, dass sie ihren Confrater Thomas Sailly auf die Spur der Reliquien gebracht haben.
</Text>:
<Text id="P60" variant='solid'>
<span style={{fontWeight:800}}>60 Caput sancti Werneri. </span>Photo of a skullcap from Speyer. The alleged skullcap of Good Werner was found in 1970 during excavation work in the St Afra chapel of Speyer Cathedral in a box with relics that came from the Mainz cathedral treasury. It could have come to the Mainz Jesuits in 1621 as thanks for bringing their confrater Thomas Sailly on the trail of the relics.
</Text>
}
</Box>
</SimpleGrid>
</motion.div>
    )
}